<template>
    <div class="page-content-box MT10">
        <div class="page-filter-box">
            <el-row :gutter="16">
                <el-col :xs="24" :sm="12" :md="8" :xl="6">
                    <el-input v-model="params.queryValue" class="input-with-select" size="small">
                        <el-select v-model="params.queryType" slot="prepend" placeholder="请选择">
                            <el-option label="众筹名称" :value="1"></el-option>
                            <el-option label="众筹ID" :value="2"></el-option>
                        </el-select>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :xl="6">
                    <el-select v-model="params.firstPlayStatus" placeholder="请选择首款申请状态" size="small">
                        <el-option value="" label="不限制首款申请状态"></el-option>
                        <el-option :value="1" label="仅看已申请的"></el-option>
                        <el-option :value="0" label="仅看未申请的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :xl="6">
                    <el-select v-model="params.firstReceivablesStatus" placeholder="请选择首款结款状态" size="small">
                        <el-option value="" label="不限制首款结款状态"></el-option>
                        <el-option :value="3" label="仅看不可结款的"></el-option>
                        <el-option :value="1" label="仅看未结款的"></el-option>
                        <el-option :value="2" label="仅看已结款的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :xl="6">
                    <el-select v-model="params.lastPlayStatus" placeholder="请选择尾款申请状态" size="small">
                        <el-option value="" label="不限制尾款申请状态"></el-option>
                        <el-option :value="1" label="仅看已申请的"></el-option>
                        <el-option :value="0" label="仅看未申请的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :xl="6">
                    <el-select v-model="params.lastReceivablesStatus" placeholder="请选择尾款结款状态" size="small">
                        <el-option value="" label="不限制尾款结款状态"></el-option>
                        <el-option :value="3" label="仅看不可结款的"></el-option>
                        <el-option :value="1" label="仅看未结款的"></el-option>
                        <el-option :value="2" label="仅看已结款的"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="16" :lg="14" :xl="10">
                    <div class="clearfix timeType">
                        <el-input class="input-with-select fl" size="small">
                            <el-select v-model="params.queryTimeType" slot="prepend" placeholder="请选择">
                                <el-option label="众筹结束时间" :value="1"></el-option>
                                <el-option label="首款申请时间" :value="2"></el-option>
                                <el-option label="尾款申请时间" :value="3"></el-option>
                                <el-option label="首款打款时间" :value="4"></el-option>
                                <el-option label="尾款打款时间" :value="5"></el-option>
                            </el-select>
                        </el-input>
                        <el-date-picker
                            v-model="datetimerange"
                            type="datetimerange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            class="fl"
                            size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            unlink-panels
                            >
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="2" class="page-filter-btns">
                    <el-button type="primary" @click="search" size="small">搜索</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                style="width: 100%"
                v-loading="listLoading"
                size="small"
                header-row-class-name="page-list-table"
            >
                <el-table-column prop="id" label="众筹ID"></el-table-column>
                <el-table-column prop="title" label="众筹名称" min-width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status" label="众筹状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 2" type="success" size="mini">众筹中</el-tag>
                        <el-tag v-if="scope.row.status == 4" type="info" size="mini">已完成</el-tag>
                        <el-tag v-if="scope.row.status == 99" type="warning" size="mini">已结束</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="众筹结束时间" min-width="145"></el-table-column>
                <el-table-column prop="raiseModel" label="众筹模式">
                    <template slot-scope="scope">{{scope.row.raiseModel == 1 ? '预售' : '普通'}}</template>
                </el-table-column>
                <el-table-column prop="raiseMoneyStr" label="目标筹款金额" min-width="100"></el-table-column>
                <el-table-column prop="saleCount" label="支持次数"></el-table-column>
                <el-table-column prop="saleMoney" label="已经筹款金额" min-width="100"></el-table-column>
                <el-table-column prop="firstPlayPriceStatus" label="首款申请状态" min-width="100"></el-table-column>
                <el-table-column prop="firstCreateTime" label="首款申请时间" min-width="145"></el-table-column>
                <el-table-column prop="firstPlayStatus" label="首款结款状态" min-width="100"></el-table-column>
                <el-table-column prop="firstPlayPriceTicketStr" label="首款打款金额" min-width="100"></el-table-column>
                <el-table-column prop="firstPlayTime" label="首款打款时间" min-width="145"></el-table-column>
                <el-table-column prop="lastPlayPriceStatus" label="尾款申请状态" min-width="100"></el-table-column>
                <el-table-column prop="lastCreateTime" label="尾款申请时间" min-width="145"></el-table-column>
                <el-table-column prop="lastPlayStatus" label="尾款结款状态" min-width="100"></el-table-column>
                <el-table-column prop="lastPlayPriceTicketStr" label="尾款打款金额" min-width="100"></el-table-column>
                <el-table-column prop="lastPlayTime" label="尾款打款时间" min-width="145"></el-table-column>

                <el-table-column fixed="right" label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.canAudi == 2" type="text" size="small" @click="showDetail(scope.row,1)">申请结款</el-button>
                        <el-button v-if="scope.row.hasInfo == 2" type="text" size="small" @click="showDetail(scope.row,2)">结款明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="data" class="page-pagination-box">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pageNo"
                :page-sizes="[15, 30, 50]"
                :page-size.sync="params.pageSize"
                layout="sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
        <ViewDetail ref="viewDetail" @onUpdate="onUpdateData"></ViewDetail>
    </div>
</template>

<script>
import ViewDetail from './viewDetail';
export default {
    components:{ViewDetail},
    data(){
        return{
            data: null,
            dataList: [],
            params: {
                endTime: "",
                firstPlayStatus: "",
                firstReceivablesStatus: "",
                lastPlayStatus: "",
                lastReceivablesStatus: "",
                pageNo: 1,
                pageSize: 15,
                queryTimeType: 1,
                queryType: 1,
                queryValue: "",
                startTime: ""
            },
            listLoading: true,
            datetimerange:[],
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        onUpdateData(){
            this.$parent.$refs.statistics.getData();
            this.getDataList();
        },
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/raise/receivables/getRiseAudiPage",
                method: "POST",
                data: {
                    ...this.params,
                    startTime:this.datetimerange && this.datetimerange[0] || '',
                    endTime:this.datetimerange && this.datetimerange[1] || ''
                },
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result || [];
                    } else {
                        this.$message.error(msg || "获取申请列表信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取申请列表信息错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        showDetail(data,type){
            this.$refs.viewDetail.show(data,type);
        }
    }
}
</script>
