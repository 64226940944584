<template>
    <el-drawer
        v-if="raiseData"
        :title="type == 2 ? '结款明细' : '申请结款'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <div v-if="raiseData" class="group">
                <div class="page-group-title">众筹信息</div>
                <table class="info-table MT20" width="100%">
                    <tr>
                        <th>众筹ID</th>
                        <td>{{raiseData.id}}</td>
                        <th>众筹名称</th>
                        <td>{{raiseData.title}}</td>
                    </tr>
                    <tr>
                        <th>众筹模式</th>
                        <td>{{raiseData.raiseModel == 1 ? '预售' : '普通'}}</td>
                        <th>众筹状态</th>
                        <td>
                            <span v-if="raiseData.status == 2" >众筹中</span>
                            <span v-if="raiseData.status == 4">已完成</span>
                            <span v-if="raiseData.status == 99">已结束</span>
                        </td>
                    </tr>
                    <tr>
                        <th>众筹结束时间</th>
                        <td>{{raiseData.endTime}}</td>
                        <th>目标筹款金额</th>
                        <td>{{raiseData.raiseMoneyFormat}}</td>
                    </tr>
                    <tr>
                        <th>支持次数</th>
                        <td>{{raiseData.supportNum}}</td>
                        <th>已经筹款金额</th>
                        <td class="color-danger">{{raiseData.raisedMoneyFormat}}</td>
                    </tr>
                    <tr>
                        <th>首款预计金额</th>
                        <td>{{raiseData.downPaymentEstimateMoney}}</td>
                        <th>尾款预计金额</th>
                        <td>{{raiseData.retainageEstimateMoney}}</td>
                    </tr>
                </table>
            </div>
            <div v-if="receivablesAudiInfo" class="group MT20">
                <div class="page-group-title">结款信息 <span class="color-danger">(总打款金额:{{receivablesAudiInfo.totalPrice}})</span></div>

                <table v-if="receivablesAudiInfo.firstCreateTime" class="info-table MT20" width="100%">
                    <tr>
                        <th>首款申请时间</th>
                        <td>{{receivablesAudiInfo.firstCreateTime}}</td>
                        <th>首款打款时间</th>
                        <td>{{receivablesAudiInfo.firstPlayTime}}</td>
                    </tr>
                    <tr>
                        <th>首款打款金额</th>
                        <td>{{receivablesAudiInfo.firstPlayPriceTicket}}</td>
                        <th>首款打款流水号</th>
                        <td>{{receivablesAudiInfo.firstPlayPriceNo}}</td>
                    </tr>
                    <tr>
                        <th>首款申请备注</th>
                        <td colspan="3">{{receivablesAudiInfo.firstApplyDesc}}</td>
                    </tr>
                    <tr>
                        <th>首款打款截图</th>
                        <td colspan="3">
                            <div v-if="receivablesAudiInfo.firstPlayPic" class="img">
                                <el-image v-for="(item,index) in receivablesAudiInfo.firstPlayPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="receivablesAudiInfo.firstPlayPic.split(',')"></el-image>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>首款打款备注</th>
                        <td colspan="3">{{receivablesAudiInfo.firstPlayDesc}}</td>
                    </tr>
                </table>

                <table v-if="receivablesAudiInfo.lastCreateTime" class="info-table MT20" width="100%">
                    <tr>
                        <th>尾款申请时间</th>
                        <td>{{receivablesAudiInfo.lastCreateTime}}</td>
                        <th>尾款打款时间</th>
                        <td>{{receivablesAudiInfo.lastPlayTime}}</td>
                    </tr>
                    <tr>
                        <th>尾款打款金额</th>
                        <td>{{receivablesAudiInfo.lastPlayPriceTicket}}</td>
                        <th>尾款打款流水号</th>
                        <td>{{receivablesAudiInfo.lastPlayPriceNo}}</td>
                    </tr>
                    <tr>
                        <th>尾款申请备注</th>
                        <td colspan="3">{{receivablesAudiInfo.lastApplyDesc}}</td>
                    </tr>
                    <tr>
                        <th>尾款打款截图</th>
                        <td colspan="3">
                            <div v-if="receivablesAudiInfo.lastPlayPic" class="img">
                                <el-image v-for="(item,index) in receivablesAudiInfo.lastPlayPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="receivablesAudiInfo.lastPlayPic.split(',')"></el-image>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>尾款打款备注</th>
                        <td colspan="3">{{receivablesAudiInfo.lastPlayDesc}}</td>
                    </tr>
                </table>

            </div>
            <div v-if="receivablesInfo" class="group MT20">
                <div class="page-group-title">收款信息</div>
                <table class="info-table MT20" width="100%">
                    <tr>
                        <th>收款联系人姓名</th>
                        <td>{{receivablesInfo.userName}}</td>
                        <th>银行</th>
                        <td>{{receivablesInfo.bank}}</td>
                    </tr>
                    <tr>
                        <th>收款人联系手机</th>
                        <td>{{receivablesInfo.userTelephone}}</td>
                        <th>开户网点</th>
                        <td>{{receivablesInfo.bankAddress}}</td>
                    </tr>
                    <tr>
                        <th>收款人联系邮箱</th>
                        <td>{{receivablesInfo.userEmail}}</td>
                        <th>账户名</th>
                        <td>{{receivablesInfo.bankAccount}}</td>
                    </tr>
                    <tr>
                        <th>通讯地址</th>
                        <td>{{receivablesInfo.address}}</td>
                        <th>卡号</th>
                        <td>{{receivablesInfo.bankCardId}}</td>
                    </tr>
                    <tr>
                        <th>账户性质</th>
                        <td colspan="3">{{receivablesInfo.accountType == 1 ? '企业' : '个人'}}</td>
                    </tr>
                </table>
            </div>
            <div v-if="type == 1" class="group MT20">
                <div class="page-group-title">备注</div>
                <el-form
                    :model="formData"
                    ref="formData"
                    label-position="top"
                    class="MT20"
                >
                    <el-form-item prop="remark">
                        <el-input v-model.trim="formData.remark" type="textarea" :rows="2" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="drawer-footer">
            <el-button v-if="type == 2" type="primary" @click="close">确认</el-button>
            <template v-else>
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submitForm()" :loading="btnLoading" v-if="raiseData.canAudi == 2">申请尾款</el-button>
            </template>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{
                remark:'',
                id:'',
                audiType:1,
            },
            raiseData: null,
            type:1,
            receivablesAudiInfo: null,
            receivablesInfo: null,
            dataLoading: false
        }
    },
    methods:{
        close(){
            if(this.$refs.formData) this.$refs.formData.resetFields();
            this.receivablesAudiInfo = null;
            this.receivablesInfo = null;
            this.drawerShow = false;
        },
        show(raiseData,type){
            this.raiseData = raiseData;
            this.type = type;
            this.getData();
            this.drawerShow = true;
        },

        getData(){
            this.dataLoading = true;
            this.$request({
                url: "/raise/receivables/raiseApplyAudiInfo",
                method: "POST",
                params:{id:this.raiseData.id}
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.raiseData = result;
                        this.receivablesAudiInfo = result.receivablesAudiRaise || null;
                        this.receivablesInfo = result.receivablesInfo || null;
                    } else {
                        this.$message.error(msg || "获取信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息错误");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //表单提交
        submitForm() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    const _url = "/raise/receivables/submitAudiInfo";
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        params: {...this.formData,id:this.raiseData.id,audiType:this.raiseData.canAudi},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$message.success("提交成功");
                                this.$emit('onUpdate');
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.img{
    .el-image{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
th{
    width: 130px;
}
</style>